import React, { useEffect, useState } from 'react';
import QuickBI from '@/components/QuckBI';

const PANEL_NO = 'p_wowcarp_site_member';

export default function DataBoard() {
  const [values, setValues] = useState<Record<string, any>>();

  useEffect(() => {
    setValues({});
  }, []);

  return <QuickBI panelNo={PANEL_NO} values={values} />;
}
