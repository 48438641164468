import React, { useEffect, useState } from 'react';
import { useDocumentVisibility, useRequest } from 'ahooks';
import Iframe from 'react-iframe';
import CryptoJS from 'crypto-js';
import { CommonStore } from '@/stores';

const SECRET_KEY = CryptoJS.enc.Utf8.parse('&rwV!pjsDv-FZjf8');

const decrypt = (data: string) => {
  return CryptoJS.AES.decrypt(data, SECRET_KEY, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
};

const getDecrptedURL = (url: string) => {
  try {
    const { origin, pathname, search } = new URL(url);
    return `${origin}${pathname}?${decrypt(search.slice(1))}`;
  } catch (error) {
    return url;
  }
};

interface QuickBIProps<T extends Record<string, any>> {
  panelNo: string;
  values?: T;
}

export default function QuickBI<T extends Record<string, any>>({
  panelNo,
  values,
}: QuickBIProps<T>) {
  const documentVisibility = useDocumentVisibility();

  const [url, setUrl] = useState('');
  const [height, setHeight] = useState('100%');

  const { run: getBoard } = useRequest(
    async () => {
      if (!panelNo || !values) {
        return;
      }

      const { panelUrl, expireTime } = await CommonStore.completedUrl({
        panelNo,
        ...values,
      });

      panelUrl && setUrl(getDecrptedURL(panelUrl));
      expireTime &&
        localStorage.setItem(
          `${panelNo}_expire_time`,
          (expireTime * 60 * 1000 + Date.now()).toString(),
        );
    },
    {
      refreshDeps: [values],
    },
  );

  useEffect(() => {
    if (documentVisibility === 'visible') {
      const expireTime = localStorage.getItem(`${panelNo}_expire_time`);

      if (expireTime && Date.now() > parseInt(expireTime, 10)) {
        getBoard();
      }
    }
  }, [documentVisibility]);

  useEffect(() => {
    getBoard();
  }, []);

  useEffect(() => {
    const quickBIURL = ['https://bi.aliyuncs.com'];
    const onMessage = (event: MessageEvent) => {
      if (quickBIURL.includes(event.origin)) {
        setHeight(event.data.height ? `${event.data.height}px` : '100%');
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return <Iframe url={url} width="100%" height={height} frameBorder={0} />;
}
