import { getMainDomain } from '@/tools/common';
import type { FleAppEnv, EnvURLMap } from '@/types/const/serviceEnv';

export const fleAppEnv: FleAppEnv = fleProcess.fleEnv;
type ServerType = 'java';
type BaseURLMap = {
  [K in ServerType]: EnvURLMap;
};
export const baseURLMap: BaseURLMap = {
  java: {
    dev: 'https://dev-gateway.fxqifu.net/pangu',
    qa: 'https://qa-gateway.fxqifu.net/pangu',
    prod: 'https://gateway.fxqifu.net/pangu',
  },
};

type codeType = number;
export const DataCode: Map<string, codeType> = new Map([
  ['SUCCESS', 2000],
  ['LOGIN_ERROR', 100003],
  ['INVALID_LOGIN', 60000013],
]);

export const BaseServiceType = 'java';
export const TokenKey = 'token';
export const BaseURL: string = baseURLMap[BaseServiceType][fleAppEnv] || '';

export type ApplicationType =
  | 'share'
  | 'home'
  | 'help'
  | 'cardStore'
  | 'liguanjia'
  | 'peliten'
  | 'csc'
  | 'cscUser'
  | 'login'
  | 'supplier';
type ApplicationUrlMapType = {
  [K in ApplicationType]: EnvURLMap;
};

let domin = getMainDomain();
domin = domin === 'localhost' ? `carpunion.com` : domin;

export const ApplicationUrlMap: ApplicationUrlMapType = {
  // 分享
  share: {
    dev: `https://dev.${domin}/share`,
    qa: `https://qa.${domin}/share`,
    prod: `https://console.${domin}/share`,
  },
  // 主页
  home: {
    dev: `https://dev.${domin}`,
    qa: `https://qa.${domin}`,
    prod: `https://console.${domin}`,
  },
  // 登录
  login: {
    dev: `https://dev-account.${domin}`,
    qa: `https://qa-account.${domin}`,
    prod: `https://account.${domin}`,
  },
  // 帮助中心
  help: {
    dev: 'https://dev-help.carpunion.com',
    qa: 'https://qa-help.carpunion.com',
    prod: 'https://help.carpunion.com',
  },
  // 卡券
  cardStore: {
    dev: `https://qa-cardstore.${domin}`,
    qa: `https://qa-cardstore.${domin}`,
    prod: `https://cardstore.${domin}`,
  },
  // 礼管家
  liguanjia: {
    dev: `https://qa-liguanjia.${domin}`,
    qa: `https://qa-liguanjia.${domin}`,
    prod: `https://liguanjia.${domin}`,
  },
  // 百礼腾
  peliten: {
    dev: 'http://dev-www.peliten.com',
    qa: 'https://qa-www.peliten.com',
    prod: 'https://www.peliten.com',
  },
  // 客服
  csc: {
    dev: 'https://dev-csc.carpunion.com/service',
    qa: 'https://qa-csc.carpunion.com/service',
    prod: 'https://csc.carpunion.com/service',
  },
  // 客服-用户入口
  cscUser: {
    dev: 'https://dev-csc.carpunion.com/customer/chart',
    qa: 'https://qa-csc.carpunion.com/customer/chart',
    prod: 'https://csc.carpunion.com/customer/chart',
  },
  // 供应商端
  supplier: {
    dev: `https://supplier.koudaiqifu.cn`,
    qa: `https://supplier.koudaiqifu.cn`,
    prod: `https://supplier.fxqifu.com`,
  },
};

export const getApplicationUrl = (application: ApplicationType, router = '') => {
  return `${ApplicationUrlMap[application]?.[fleAppEnv]}${router}`;
};
